import React from 'react'
import Head from '../Header/Header'
import Foot from '../Footer/Footer'
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import {ReactComponent as Automation} from '../../img/icons/automation.svg';
import {ReactComponent as Embedded} from '../../img/icons/embedded-solutions.svg';
import {ReactComponent as Access} from '../../img/icons/access-gateway.svg';
import {ReactComponent as Lending} from '../../img/icons/lending.svg';
import {ReactComponent as Partners} from '../../img/icons/partners.svg';
import {ReactComponent as NewProduct} from '../../img/icons/newproductmanage.svg';
import {ReactComponent as Rule} from '../../img/icons/rule-engine.svg';
import {ReactComponent as VAS} from '../../img/icons/vas.svg';


const { Content } = Layout;
const { Title } = Typography;

export default function Banks() {
  return (
    <>
      <Head />
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:24}} lg={{span:24}}>
                    <Title level={2} className="sectionTitle text-center">One journey for all</Title>
                    <div className="page_description d-none-xs">
                      <ul className='page_ul'>
                          <li>Grow your distribution</li>
                          <li>Create new products</li>
                          <li>Integrate APIs with partners</li>
                          <li>Offer STP Journeys</li>
                      </ul>
                    </div>
                </Col>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}}>
                  <Link className='extLink' to="/integrate/0"><Automation /> Bank/Insurance APIs</Link>
                  <Link className='extLink' to="/connect/1"><Lending /> Ecosystem Creation</Link>
                  <Link className='extLink' to="/integrate/1"><Embedded /> Embedded Solutions</Link>
                  <Link className='extLink' to="/decide/2"><VAS /> Value Added Services</Link>
                  <Link className='extLink' to="/integrate/2"><Access /> Workflow Solutions</Link>
                  <Link className='extLink' to="/decide/1"><Rule /> Business Rule Engines</Link>
                </Col>
                <Link to="/contact" className='btn-orange mt-50 mt-xs-0'>Contact Us</Link>
              </Row>
              
            </div>
        </Content>
      </Layout>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center d-none-xs">Lower cost of acquisition & distribution</Title>
              <Title level={2} className="sectionTitle text-center d-visible-xs mb-40">Lower distribution cost</Title>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}>
                    <div className='br12 mb-20 text-center'>
                      <img src={require('../../img/channel-management1.png')} style={{width:'80%'}} alt=''/>
                    </div>
                    <Title level={4} className="sectionTitle text-left">Channel management</Title>
                    <p>Mange teams & their partners centrally. CP productivity improves massively by digitizing executions, pushing qualified leads & documents digitally. Also, allow tracking of performance in real time. Deploy attention where needed</p>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}>
                  <div className='br12 mb-20 text-center'>
                    <img src={require('../../img/Decisioning.png')} style={{width:'80%'}} alt=''/>
                  </div>
                    <Title level={4} className="sectionTitle text-left">Customized Decisioning</Title>
                    <p>Build customized underwriting & business rule engines with traditional & alternate data sets for best outputs. First interface that allows for inclusion of industry experience into business processes. Better decisions at faster rates & lowest cost</p>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 8}}>
                  <div className='br12 mb-20 text-center'>
                    <img src={require('../../img/vas1.png')} style={{width:'80%'}} alt=''/>
                  </div>
                    <Title level={4} className="sectionTitle text-left">Product Builder</Title>
                    <p>Design, test, & launch forms, journeys & products in days & not months. Products can be simulated on OI ecosystem to understand future outcomes. And then customization can be done at  any scale required-product, team,customer & more</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      <Layout className='grey'>
        <Content>
            <div className="site-layout-content">
              <Title level={2} className="sectionTitle text-center">Set the network to work for you</Title>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:24}} lg={{span: 24}} className="text-center footer_logos">
                  <img src={require('../../img/lenders/fineoteric-logo.png')} alt=''/>
                  <img src={require('../../img/lenders/rupeeboss.png')} alt=''/>
                  <img src={require('../../img/lenders/Royal Finserv Logo.jpg')} alt=''/>
                  <img src={require('../../img/lenders/Vattakosh.jpg')} alt=''/>
                  <img src={require('../../img/lenders/hrone.png')} alt=''/>
                  <img src={require('../../img/lenders/razorpay.png')} alt=''/>
                  <img src={require('../../img/lenders/Quess_Logo.png')} alt=''/>
                  <img src={require('../../img/lenders/neverowned.png')} alt=''/>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
      {/* <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-between" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 6}}>
                  <Title level={4} className="">Case Study</Title>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 18}} className='d-flex justify-space-between'>
                  <div className='case_study'>
                    <Title level={5} className="">Project Year</Title>
                    <span>2022</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5} className="">Bank Type</Title>
                    <span>Digital Greenfield</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5} className="">Cloud Partner</Title>
                    <span>OpenShift and Google Cloud</span>
                  </div>
                  <div className='case_study'>
                    <Title level={5} className="">Geography</Title>
                    <span>India</span>
                  </div>
                </Col>
              </Row>
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="mt-50 align-items-center">
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <img src={require('../../img/automation.png')} style={{width:'100%'}} alt=''/>
                </Col>
                <Col xs={{span:24}} md={{span:12}} lg={{span: 12}}>
                  <Title level={4} className="sectionTitle text-left">Challenges</Title>
                  <p style={{marginBottom:'50px'}}>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>

                  <Title level={4} className="sectionTitle text-left">The Solution</Title>
                  <p>Italy’s largest banking group, Intesa Sanpaolo, planned a four-year vision for the evolution of their organisation. 
                    This wide-ranging plan covered cost reduction, technology modernisation, customer experience and more. Intesa Sanpaolo 
                    sought a technology partner – in the truest sense of the word – a core banking vendor that had the technology 
                    credentials, the experience of working with Tier 1 banks, and the right architecture to be a close partner over 
                    the years ahead and truly modernise their service.</p>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout> */}
      <Foot />
    </>
  )
}
